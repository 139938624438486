jQuery(document).ready(function($) { 

    // Set variables 
    // var d              = 0;
    // var t              = 0;
    var formPage       = document.title;
    // var formIdentifier = 'Error - no Gravity Form found';

    // $('.gform_wrapper :input').focus(function () {
    //     d = new Date();
    //     t = d.getTime();
    // });

    // $('.gform_wrapper :input').blur(function() {
    //     var form           = $(this).closest('div.gform_wrapper form');
    //     var formIdentifier = $(form).attr('class') || $(form).attr('id');
    //     var fieldLabel     = $(this).closest('li').find('label').text();
    //     var e              = new Date();
    //     var x              = e.getTime();
    //     var exit           = Math.round((x - t)/1000);

    //     if($(this).val().length > 0){
    //     	//console.log('Page: ' + formPage + ' Field filled ' + fieldLabel);
    //         ga('send', 'event', 'Page: ' + formPage, 'Field filled', fieldLabel, exit);
    //     } else {
    //     	//console.log('Page: ' + formPage + ', Form: ' + formIdentifier + ' Field skipped ' + fieldLabel);
    //        	ga('send', 'event', 'Page: ' + formPage, 'Field filled', fieldLabel, exit);
    //     }
    // });
 
    // $( ".gform_wrapper input[type=checkbox], .gform_wrapper [type=radio]" ).click (function () {
    //     var form           = $(this).closest('div.gform_wrapper form');
    //     var formIdentifier = $(form).attr('class') || $(form).attr('id');
    //     var fieldSetLabel  = $(this).closest('li.gfield').find('label').first().text();
    //     var fieldValue     = $(this).val();

    //     if ($(this).attr("checked") == "checked") {
    //     	//console.log('Page: ' + formPage + ', Form: ' + formIdentifier + ' Checkbox/Radio checked ' + fieldSetLabel);
    //     		ga('send', 'event', 'Page: ' + formPage, 'Checkbox/Radio checked', fieldSetLabel + ': ' + fieldValue);
    //     } else {
    //     	//console.log('Page: ' + formPage + ', Form: ' + formIdentifier + ' Checkbox/Radio unchecked ' + fieldSetLabel);
    //         ga('send', 'event', 'Page: ' + formPage, 'Checkbox/Radio unchecked', fieldSetLabel + ': ' + fieldValue);
    //     }
    // });
    
    // $( ".gform_wrapper input[type=submit]" ).click (function () {
    //     var form           = $(this).closest('div.gform_wrapper form');
    //     var formIdentifier = $(form).attr('class') || $(form).attr('id');
    //     //console.log(formIdentifier + ' clicked submit');
    //     ga('send', 'event', 'Page: ' + formPage, 'Form submitted', 'submit');
    // });

	var next_clicked = false;

   $('.gform_next_button').on('click', function(){
   	if (!next_clicked) {
   		console.log('Page:' + formPage + ' Form next button clicked');
   		ga('send', 'event', 'Page: ' + formPage, 'Form next button clicked', 'click');
   		next_clicked = true;
   	}
   });

   $(document).on('gform_confirmation_loaded', function(event, formId){
   	if (next_clicked) {
   		console.log('Page: ' + formPage + ' Form submitted confirmation');
      	ga('send', 'event', 'Page: ' + formPage, 'Form submitted confirmation', 'submit');
      	next_clicked = false;
      }
	});
    
 }); 
