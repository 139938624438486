/**
 * Toolkit JavaScript
 */

(function($) {

/**
 * StickyJS header
 **/

if ($('.wrap').length > 0) {
	$(".Header").sticky({topSpacing:0});
}


/**
 * Homepage hero carousel
 **/

var home_slider = $('.Home-hero-slides').bxSlider({
	'mode': 'fade',
	'auto': true,
	'speed': 800,
	'pause': 5000,
	'controls': false,
	'adaptiveHeight': true,
	onSlideBefore: function() {
		$('.Home-hero-slide-text').addClass('hide');
	},
	onSlideAfter: function() {
		$('.Home-hero-slide-text').removeClass('hide');
	},
});

/**
 * Reviews carousel
 **/

var reviews_carousel = $('.ReviewCarousel-reviews').bxSlider({
	'controls': false,
	'pager': false,
	'auto': true,
	'autoStart': true,
	'adaptiveHeight': true,
	'pause': 6000
});

/**
 *	Homepage conservatory styles mobile carousel
 */

var home_styles_slider_loaded = false;
if ($(window).width() < 620 && $('.Home-stylesGrid .mobile-slider').length>0) {
	var home_styles_slider = $('.Home-stylesGrid .mobile-slider').bxSlider({
		'controls': true
	});
	home_styles_slider_loaded = true;
}

$(window).on('resize', function() {
	if ($(window).width() >= 620 && home_styles_slider_loaded) {
		home_styles_slider.destroySlider();
		home_styles_slider_loaded = false;
	}
});

/**
 * Customer gallery carousel
 **/

$(window).on('load', function() {
	var gallery_carousel = $('.GalleryCarousel-slides').bxSlider({
		'controls': true,
		'pager': false,
		'adaptiveHeight': true,
		'minSlides': 1,
		'maxSlides': 3,
		'slideWidth': 330,
		'slideMargin': 5,
		'moveSlides': 1,
		'infiniteLoop': false
	});
});


/**
 * Accordions - FAQs and Viewings map list
 **/

var all_accordion_items = $('.accordion .accordion-item');

$('.accordion .accordion-item-heading').on('click', function() {
	if (!$(this).parent().hasClass('expanded')) {
		all_accordion_items.removeClass('expanded');
		$(this).parent().addClass('expanded');
	} else {
		$(this).parent().removeClass('expanded');
	}
});

/**
 * Map
 **/

$('.Map g > g').on('click touchend', function() {
	var region = $(this).data('region');
	$('.Map g > g').attr('class','');
	$(this).attr('class','selected');
	$('.Viewings-list-region').hide();
	$('.Viewings-list-region .accordion-item').removeClass('expanded');
	$('.'+region).fadeIn();
	if ($(window).width()<=480) {
		$('html, body').animate({
      	scrollTop: $(".Viewings-list").offset().top - 150
   	}, 600);
	}
});


/**
 * Sticky banner
 **/

 $(window).on('scroll', function() {
 	if ($(window).scrollTop() > 100){
   	$('.StickyBanner').addClass('visible');
  	} else {
  		$('.StickyBanner').removeClass('visible');
  	}
 });

/**
 * Menu disable # links
 **/

$('.disable-link > a').on('click', function(e) {
	e.preventDefault();
});

/**
 * Galleries
 **/

$.featherlightGallery.prototype.afterContent = function() {
  var caption = this.$currentTarget.find('.gallery-caption');
  this.$instance.find('.caption').remove();
  $('<div class="caption">').html(caption.clone()).appendTo(this.$instance.find('.featherlight-content'));
};

/**
 * Reviews star ratings
 **/

$.fn.stars = function() {
	return this.each(function(i,e){$(e).html($('<span/>').width($(e).text()*16));});
};

$('.stars').stars();

// Home Page carousel

// the `embla-carousel` class goes on the closest parent element
// that contains both the slides and the navigation interface, if present
$('.embla').each(function () {
	var viewPort = this.querySelector(".embla__viewport");
	var prev = this.querySelector(".embla__button--prev");
	var next = this.querySelector(".embla__button--next");

	var plugins = [];

	var autoplay = this.getAttribute('data-autoplay');
	if (autoplay > 0) {
		plugins.push(EmblaCarouselAutoplay({
			delay: autoplay,
			stopOnMouseEnter: true,
			stopOnInteraction: false,
		}, (emblaRoot) => emblaRoot.parentElement));
	}

	var embla = EmblaCarousel(viewPort, {
		loop: false,
		skipSnaps: false,
		containScroll: 'trimSnaps',
		align: 'start',
	}, plugins);

	function disablePrevAndNextBtns() {
    if (embla.canScrollPrev()) {
			prev.removeAttribute('disabled');
		}
    else {
			prev.setAttribute('disabled', 'disabled');
		}

    if (embla.canScrollNext()) {
			next.removeAttribute('disabled');
		}
    else {
			next.setAttribute('disabled', 'disabled');
		}
	}

	if (prev && next) {
		prev.addEventListener('click', embla.scrollPrev, false);
		next.addEventListener('click', embla.scrollNext, false);

		embla.on("select", disablePrevAndNextBtns);
		embla.on("init", disablePrevAndNextBtns);
	}

});

})(jQuery);
