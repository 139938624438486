(function ($) {
	var categories = document.getElementById('js-blogCategories');
	var categoryInputs = document.querySelectorAll('input[name="category_name"]');
	var $blogLoading = $('.blog-list .blog-loading');
	var $blogPosts = $('.blog-list-posts');
	var allCategories = categoryInputs[0]; // first category input is 'All articles'
	var wrapper = document.querySelector('.wrap');

	if (!categories) return;

	categories.addEventListener('change', function (e) {
		var i, allCategoriesSelected = false;
		// when selecting all articles
		if (e.target === allCategories) {
			allCategoriesSelected = true;
			for (i = 1; i < categoryInputs.length; i++) {
				categoryInputs[i].checked = false;
			}
		}
		// when selecting any other item
		else if (e.target.checked) {
			allCategoriesSelected = false;

			for (i = 1; i < categoryInputs.length; i++) {
				if (categoryInputs[i] === e.target) continue;
				categoryInputs[i].checked = false;
			}
		}
		// when deselecting an item
		else {
			allCategoriesSelected = true;
			for (i = 1; i < categoryInputs.length; i++) {
				if (categoryInputs[i].checked) {
					allCategoriesSelected = false;
					break;
				}
			}
		}

		allCategories.checked = allCategories.disabled = allCategoriesSelected;

 		loadPosts();
		$('.posts-navigation').hide();
	});

	// when navigating the filter list with the keyboard
	categories.addEventListener('focus', function (e) {
		// make sure the selected item is in view
		e.target.nextElementSibling.scrollIntoView({ inline: 'nearest', block: 'nearest'});
		// and that the window doesn't shift
		wrapper.scrollLeft = 0;
	}, true);
	
	function loadPosts(page) {
		var i;
		var cats = [];

		for (i = 0; i < categoryInputs.length; i++) {
			if (categoryInputs[i].checked) {
				cats.push(categoryInputs[i].value);
			}
		}
	
		$.ajax({
			url: loadcatposts.ajax_url,
			type: 'post',
			data: {
				action: 'loadcatposts',
				cat: cats.join(),
				page: page || 1
			},
			beforeSend: function() {
				$blogLoading.fadeIn(120);
			},
			success: function (html) {
				if (html !== 'Sorry, no results were found.') {
					$blogPosts.empty().html(html).fadeIn();
					$blogLoading.fadeOut(120);
				}
				else {
					$('#js-loadMoreTrigger').text('There are no more posts to show').attr('disabled','true');
				}
			} // success
		}); // ajax
	}
})(jQuery);
